// project import
// import pages from './pages';
import dashboard from './dashboard';
import services from './services';
import member from './member';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [services, dashboard, pages]
    items: [services, member, dashboard]
};

export default menuItems;
