import { useState, useEffect } from 'react';
// material-ui
// import { Box, IconButton, Link, useMediaQuery } from '@mui/material';

// project import
// import Profile from './Profile';
// import Notification from './Notification';
// import MobileSection from './MobileSection';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

// ==============================|| HEADER - CONTENT ||============================== //
const HeaderContent = () => {
    // const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (clicked) {
            // do something meaningful, Promises, if/else, whatever, and then
            window.location.assign('https://auth.zbreg.org/realms/zbreg/protocol/openid-connect/logout');
        }
    });

    return (
        <Stack sx={{ width: '100%' }} direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ ml: 1 }} />
            {/* <Notification /> */}
            {/* {!matchesXs && <Profile />} */}
            {/* {matchesXs && <MobileSection />} */}
            <Typography variant="h4" sx={{ color: '#FFF' }}>
                ZB Registration
            </Typography>
            <IconButton onClick={() => setClicked(true)}>
                <LogoutIcon sx={{ color: '#FFF' }} />
            </IconButton>
        </Stack>
    );
};

export default HeaderContent;
