// ==============================|| OVERRIDES - TABS ||============================== //

export default function Tabs() {
    return {
        MuiTabs: {
            styleOverrides: {
                vertical: {
                    overflow: 'visible'
                },
                root: {
                    '& .MuiTabs-indicator': {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent'
                    }
                }
            }
        }
    };
}
