// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    config: {}
};

// ==============================|| SLICE - CONFIG ||============================== //

const appConfig = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        loadConfig(state, action) {
            state.config = action.payload;
        }
    }
});

export default appConfig.reducer;

export const { loadConfig } = appConfig.actions;
