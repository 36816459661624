import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// render - pages
const CreateEvent = Loadable(lazy(() => import('pages/events/Create')));
const ViewEvents = Loadable(lazy(() => import('pages/events/View')));
const AddNewMember = Loadable(lazy(() => import('pages/member/AddNewMember')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <ViewEvents />
        },
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'view-event',
            element: <ViewEvents />
        },
        {
            path: 'create-event',
            element: <CreateEvent />
        },
        {
            path: 'new-member',
            element: <AddNewMember />
        }
    ]
};

export default MainRoutes;
