import { isStringNullOrEmpty, isArrayNullOrEmpty } from '../utils/Validations';

//Init
const initConfig = (config) => {
    return Object.freeze({
        host: {
            serverUrl: config.REACT_APP_BACKEND_SERVER_ADDR,
            authUrl: config.REACT_APP_AUTH_URL
        },
        api: {
            getMemberListByEventId: config.REACT_APP_API_GET_MLIST_BY_EID,
            setMemberPresent: config.REACT_APP_API_SET_MEMBER_PRESENT,
            setMemberAbsent: config.REACT_APP_API_SET_MEMBER_ABSENT,
            getMemberInfo: config.REACT_APP_API_GET_MEMBER_INFO,
            setMemberInfo: config.REACT_APP_API_ADD_NEW_MEMBER,
            getActiveEvents: config.REACT_APP_API_GET_ACTIVE_EVENTS,
            getUpcomingEvents: config.REACT_APP_API_GET_UPCOMING_EVENTS,
            getOngoingEvents: config.REACT_APP_API_GET_ONGOING_EVENTS,
            getPastEvents: config.REACT_APP_API_GET_PAST_EVENTS,
            getAllMembershipStatuses: config.REACT_APP_API_GET_ALL_MEMBERSHIP_STATUSES,
            getAllServices: config.REACT_APP_API_GET_ALL_SERVICES
        }
    });
};

//Recursive function to loop through all nested objects in config
const validateConfig = (config) => {
    let invalidKeys = [];

    Object.entries(config).map(([key, value]) => {
        if (typeof value === 'undefined' || (typeof value === 'string' && isStringNullOrEmpty(value))) {
            invalidKeys.push(key);
        } else if (typeof value == 'object') {
            invalidKeys = invalidKeys.concat(validateConfig(value));
        }
    });

    return invalidKeys;
};

export const loadConfig = (config) => {
    let result = {
        isValid: true,
        configData: null
    };

    //Init Config
    const fullConfig = initConfig(config);

    //Validate config
    let invalidKeys = validateConfig(fullConfig);

    if (isArrayNullOrEmpty(invalidKeys)) {
        result.isValid = true;
        result.configData = fullConfig;
    } else {
        result.isValid = false;
        result.configData = invalidKeys;
    }

    return result;
};
