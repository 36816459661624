import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import { loadConfig } from 'config/loadConfig';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './Keycloak';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

//Import .env config
require('dotenv').config();
const loadConfigResult = loadConfig(process.env);
const configData = loadConfigResult.configData;

ReactDOM.render(
    <StrictMode>
        <ReactKeycloakProvider initOptions={{ onLoad: 'login-required' }} authClient={keycloak}>
            <ReduxProvider store={store}>
                <BrowserRouter>
                    <App configData={configData} />
                </BrowserRouter>
            </ReduxProvider>
        </ReactKeycloakProvider>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
