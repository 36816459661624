import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import SecondaryLayout from 'layout/SecondaryLayout';

const Attendance = Loadable(lazy(() => import('pages/attendance')));

// ==============================|| MAIN ROUTING ||============================== //

const SecondaryRoutes = {
    path: '/',
    element: <SecondaryLayout />,
    children: [
        {
            path: 'attendance/:eventIdStr/:serviceName/:eventIsActiveStr',
            element: <Attendance />
        }
    ]
};

export default SecondaryRoutes;
