// assets
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

// icons
const icons = {
    PersonAddAltIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const member = {
    id: 'group-member',
    title: 'Member',
    type: 'group',
    children: [
        {
            id: 'addNewMember',
            title: 'Add New Member',
            type: 'item',
            url: '/new-member',
            icon: icons.PersonAddAltIcon,
            breadcrumbs: false,
            disabled: false
        }
    ]
};

export default member;
