// assets
import { TeamOutlined, CalendarOutlined } from '@ant-design/icons';
import { AddCircleOutlineOutlined, EventNoteOutlined } from '@mui/icons-material';

// icons
const icons = {
    TeamOutlined,
    CalendarOutlined,
    AddCircleOutlineOutlined,
    EventNoteOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const services = {
    id: 'group-services',
    title: 'Services',
    type: 'group',
    children: [
        {
            id: 'events',
            title: 'Events',
            type: 'collapse',
            url: '',
            icon: icons.CalendarOutlined,
            breadcrumbs: true,
            subItems: [
                {
                    id: 'viewEvents',
                    title: 'View Events',
                    type: 'item',
                    url: '/view-event',
                    icon: icons.EventNoteOutlined
                },
                {
                    id: 'createEvent',
                    title: 'Create Event',
                    type: 'item',
                    url: '/create-event',
                    icon: icons.AddCircleOutlineOutlined,
                    disabled: true
                }
            ]
        }
    ]
};

export default services;
