//React
import { useEffect } from 'react';

//Redux
import { useDispatch } from 'react-redux';
import { loadConfig } from 'store/reducers/config';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useKeycloak } from '@react-keycloak/web';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = (configData) => {
    const dispatch = useDispatch();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        //Store config data
        dispatch(loadConfig(configData));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (keycloak.authenticated) {
        return (
            <ThemeCustomization>
                <ScrollTop>
                    <Routes />
                </ScrollTop>
            </ThemeCustomization>
        );
    }
    return null;
};

export default App;
