// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

// react router
import { useNavigate, useParams } from 'react-router-dom';

// assets
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    //Passing data from eventList
    const { serviceName } = useParams();

    const handleLogoutClick = () => {
        // do something meaningful, Promises, if/else, whatever, and then
        window.location.assign('https://auth.zbreg.org/realms/zbreg/protocol/openid-connect/logout');
    };

    // common header
    const mainHeader = (
        <Toolbar sx={{ backgroundColor: '#009548' }}>
            <IconButton
                onClick={() => {
                    navigate(-1);
                }}
            >
                <KeyboardBackspaceIcon sx={{ color: '#FFF' }} />
            </IconButton>
            <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1, color: '#FFF', pl: 2 }}>
                {`${serviceName}`}
            </Typography>
            <IconButton onClick={() => handleLogoutClick()}>
                <LogoutIcon sx={{ color: '#FFF' }} />
            </IconButton>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
        }
    };

    return (
        <>
            <AppBar {...appBar}>{mainHeader}</AppBar>
        </>
    );
};

export default Header;
