// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab() {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    border: '1px solid #B4B0B5',
                    borderRadius: '100px',
                    backgroundColor: '#fff',
                    color: '#009548',
                    '&.Mui-selected': {
                        backgroundColor: '#009548',
                        color: '#fff'
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: 'rgba(100, 95, 228, 0.32)'
                    },
                    marginRight: '12px',
                    minHeight: '40px',
                    maxHeight: '40px'
                }
            }
        }
    };
}
