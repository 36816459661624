export function isStringNullOrEmpty(str) {
    return !(str && typeof str === 'string' && str?.trim() !== '');
}

export function isArrayNullOrEmpty(array) {
    return !(array && array?.length > 0 && Array.isArray(array));
}

export function isJsonObjectNullOrEmpty(obj) {
    return !(obj && Object.keys(obj).length > 0 && typeof obj === 'object');
}

export function isJson(jsonStr) {
    try {
        if (typeof jsonStr === 'string') {
            JSON.parse(jsonStr);
        } else if (typeof jsonStr === 'object') {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
    return true;
}

export function isTrue(str) {
    if (!isStringNullOrEmpty(str)) {
        return str?.trim().toLowerCase() === 'true';
    }
    return false;
}

export function checkNullableBoolean(bool) {
    //returns false if bool is null, undefined or false
    return bool === true;
}
